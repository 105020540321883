/**
 * 欢迎语
 */
export const welcome = '您好!我是民意调查中心的调查员，正在进行“公众安全感民意调查”，需要耽误您几分钟时间，听听您的看法。'

/**
 * 引导语(问卷)
 */
export const guide = '导语：下面我们开始对XX的社会治安状况等情况进行调查。'

/**
 * 问题
 */
export const question = {
  // 甄别题
  distinguish: [
    {
      label: 'S1、请问您现在居住的地点是？【单选】',
      name: [
        { text: '1.提示语：{0}县（市、区） {1}乡镇（办事处）', value: '1' },
        { text: '2.以上都不是（终止访问）', value: '2' }
      ],
      type: 'radio'
    },
    {
      label: 'S2、您的年龄是:【单选】',
      name: [
        { text: '1.16周岁（含）以上', value: '1' },
        { text: '2.未满16周岁（终止访问）', value: '2' }
      ],
      type: 'radio'
    },
    {
      label: 'S3、近半年来，您有没有在XX居住或工作？【单选】',
      name: [
        { text: '1.没有（终止访问）', value: '1' },
        { text: '2.是或在', value: '2' }
      ],
      type: 'radio'
    }
  ],
  // 问卷题
  questionnaire: [
    {
      label: 'Q1、在目前的社会治安环境下，您在当地感觉安全吗？【单选】',
      name: [
        { text: '1、安全', value: '1' },
        { text: '2、比较安全', value: '2' },
        { text: '3、不太安全', value: '3' },
        { text: '4、不安全', value: '4' },
        { text: '5、不了解（不读出）', value: '5' }
      ],
      type: 'radio'
    },
    {
      label: 'Q2、您认为当地（居住地本区/县/市）存在哪些社会治安问题？【多选】',
      name: [
        { text: '1、盗窃/抢劫/拐骗', value: '1' },
        { text: '2、流氓黑恶团伙', value: '2' },
        { text: '3、非法枪支弹药', value: '3' },
        { text: '4、卖淫嫖娼', value: '4' },
        { text: '5、吸毒/贩毒', value: '5' },
        { text: '6、赌博', value: '6' },
        { text: '7、电信网络诈骗', value: '7' },
        { text: '8、危害食品药品环境安全', value: '8' },
        { text: '9、非法集资/网络传销', value: '9' },
        { text: '10、其他（请注明：  ）', value: '10' },
        { text: '11、无（不读出）', value: '11' }
      ],
      textarea: true,
      type: 'checkbox'
    },
    {
      label: 'Q3、夜间在居住地附近单独出行，您担心自己的财产或者人身安全吗？【单选】',
      name: [
        { text: '1、不担心', value: '1' },
        { text: '2、不太担心', value: '2' },
        { text: '3、比较担心', value: '3' },
        { text: '4、担心', value: '4' }
      ],
      type: 'radio'
    },
    {
      label: 'Q4_1、您对政法机关或政法队伍的执法工作是否满意？(1、公安机关)【单选】',
      name: [
        { text: '1、满意', value: '1' },
        { text: '2、比较满意', value: '2' },
        { text: '3、不太满意', value: '3' },
        { text: '4、不满意', value: '4' },
        { text: '5、不了解（不读出）', value: '5' }
      ],
      type: 'radio'
    },
    {
      label: 'Q4_2、您对政法机关或政法队伍的执法工作是否满意？(2、检察院)【单选】',
      name: [
        { text: '1、满意', value: '1' },
        { text: '2、比较满意', value: '2' },
        { text: '3、不太满意', value: '3' },
        { text: '4、不满意', value: '4' },
        { text: '5、不了解（不读出）', value: '5' }
      ],
      type: 'radio'
    },
    {
      label: 'Q4_3、您对政法机关或政法队伍的执法工作是否满意？(3、法院)【单选】',
      name: [
        { text: '1、满意', value: '1' },
        { text: '2、比较满意', value: '2' },
        { text: '3、不太满意', value: '3' },
        { text: '4、不满意', value: '4' },
        { text: '5、不了解（不读出）', value: '5' }
      ],
      type: 'radio'
    },
    {
      label: 'Q4_4、您对政法机关或政法队伍的执法工作是否满意？(4、司法行政部门)【单选】',
      name: [
        { text: '1、满意', value: '1' },
        { text: '2、比较满意', value: '2' },
        { text: '3、不太满意', value: '3' },
        { text: '4、不满意', value: '4' },
        { text: '5、不了解（不读出）', value: '5' }
      ],
      type: 'radio'
    },
    {
      label: 'Q5、您对常态化扫黑除恶工作取得的成效是否满意？【单选】',
      name: [
        { text: '1、满意', value: '1' },
        { text: '2、比较满意', value: '2' },
        { text: '3、不太满意', value: '3' },
        { text: '4、不满意', value: '4' },
        { text: '5、不了解/不清楚（不读出）', value: '5' }
      ],
      type: 'radio'
    },
    {
      label: 'Q6、您认为您身边还存在哪类黑恶势力？【多选】',
      name: [
        { text: '1、把持基层政权', value: '1' },
        { text: '2、利用家族宗族势力横行乡里', value: '2' },
        { text: '3、煽动闹事', value: '3' },
        { text: '4、强揽工程', value: '4' },
        { text: '5、欺行霸市', value: '5' },
        { text: '6、操纵经营黄赌毒', value: '6' },
        { text: '7、非法高利放贷', value: '7' },
        { text: '8、插手民间纠纷', value: '8' },
        { text: '9、利用网络进行黑恶犯罪', value: '9' },
        { text: '10、车霸路霸', value: '10' },
        { text: '11、砂霸', value: '11' },
        { text: '12、矿霸', value: '12' },
        { text: '13、其他（请注明：     ）', value: '13' },
        { text: '14、无（不读出）', value: '14' }
      ],
      textarea: true,
      type: 'checkbox'
    },
    {
      label: 'Q7、您对开展打击整治养老诈骗专项行动是否满意？【单选】',
      name: [
        { text: '1、满意', value: '1' },
        { text: '2、比较满意', value: '2' },
        { text: '3、不太满意', value: '3' },
        { text: '4、不满意', value: '4' },
        { text: '5、不了解/不清楚（不读出）', value: '5' }
      ],
      type: 'radio'
    },
    {
      label: 'Q8、您对打击整治养老诈骗专项行动有什么切身感受？【多选】',
      name: [
        { text: '1、打掉了一批养老诈骗团伙 ', value: '1' },
        { text: '2、识骗防骗的宣传增多了', value: '2' },
        { text: '3、身边受害者损失得到挽回', value: '3' },
        { text: '4、身边没有养老诈骗新发案件了', value: '4' },
        { text: '5、养老领域经营活动乱象减少了 ', value: '5' },
        { text: '6、涉养老诈骗信息、广告减少了', value: '6' },
        { text: '7、其他（请注明：     ）', value: '7' },
        { text: '8、不了解/不清楚（不读出）', value: '8' }
      ],
      textarea: true,
      type: 'checkbox'
    },
    {
      label: 'Q9、您认为打击整治养老诈骗专项行动还应在哪些方面进一步加强？【多选】',
      name: [
        { text: '1、加大打击力度 ', value: '1' },
        { text: '2、加快案件办理', value: '2' },
        { text: '3、整治规范乱象', value: '3' },
        { text: '4、加强追赃、挽回损失', value: '4' },
        { text: '5、强化对老年人的宣传教育', value: '5' },
        { text: '6、其他（请注明：     ）', value: '6' }
      ],
      textarea: true,
      type: 'checkbox'
    },
    {
      label: 'Q10、您认为您身边哪些行业领域的乱象改善最明显？【多选】',
      name: [
        { text: '1、金融放贷', value: '1' },
        { text: '2、工程建设', value: '2' },
        { text: '3、交通运输', value: '3' },
        { text: '4、市场流通', value: '4' },
        { text: '5、自然资源', value: '5' },
        { text: '6、生态环境', value: '6' },
        { text: '7、文化旅游', value: '7' },
        { text: '8、教育卫生', value: '8' },
        { text: '9、信息网络', value: '9' },
        { text: '10、乡村治理', value: '10' },
        { text: '11、其他（请注明：     ）', value: '11' },
        { text: '12、不了解/不清楚（不读出）', value: '12' }
      ],
      textarea: true,
      type: 'checkbox'
    },
    {
      label: 'Q11、在您家附近出现的矛盾纠纷、社会治安问题能不能得到有效处理？【单选】',
      name: [
        { text: '1、能得到有效处理 ', value: '1' },
        { text: '2、有人管，但效果一般', value: '2' },
        { text: '3、虽反映了，但没人管', value: '3' },
        { text: '4、不了解/不清楚（不读出）', value: '4' }
      ],
      type: 'radio'
    },
    {
      label: 'Q12、请问您对当地平安建设有什么意见或建议？',
      name: [
        { text: '1、有（请记录）', value: '1' },
        { text: '2、没有', value: '2' }
      ],
      textarea: true,
      type: 'radio'
    }
  ]
}

/**
 * 表单配置
 */
export const form = Object.values(question)
  .flat()
  .reduce((all, item) => {
    all[item.label.split('、').shift()] = {
      list: item.type === 'checkbox' ? [] : '',
      remark: ''
    }

    return all
  }, {})
